import {createTheme} from '@mui/material/styles';


export const theme = createTheme({
    palette: {
        primary: {
            main: "#A71D36",
            light: '#f1f1f1',

        },
        secondary: {
            main: "#009FDF",
        },
        info: {
            main: "#FFFF",
        },
        success: {
            main: "#9E9DA2"
        },
        action: {
            main: "#FFF"
        }

    },
    typography: {
        fontFamily: ['Montserrat', 'sans-serif'].join(','),
    },
    components: {
        MuiGrid: {
            styleOverrides: {
                root: {
                    transition: "all .2s ease-in-out"
                }
            }
        },
        MuiTextField: {
            defaultProps: {
                fullWidth: true,
                variant: "filled",
                size: "medium"
            }
        },
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    paddingLeft: 10,
                    paddingRight: 10,
                    borderRadius: 6,


                }
            }
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    paddingLeft: 10,
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: 6
                }
            }
        },
        MuiButtonBase: {
            variants: [
                {
                    props: {variant: 'efecto'},
                    style: {
                        transition: 'all .1s ease-in-out',
                        borderRadius: 10,
                        '&: hover': {
                            marginTop: -6,
                            boxShadow: "0px 0px 0px 0px rgba(0,0,0,0)",

                        }
                    },
                },


            ],

        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    borderRadius: 10,
                }
            }
        },
        MuiButton: {
            defaultProps: {
                variant: "contained",

            },
            styleOverrides: {
                root: {

                    fontWeight: 600,
                    paddingRight: 30,
                    paddingLeft: 30,
                    fontSize: 14,
                    color: "#fff"
                },

            }
        },
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    backgroundColor: "#A71D36"
                }
            }
        }

    }


})
