/**************************************************
 * Nombre:       Formulario_Productos
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {obtenerCol} from "../../Servicios/BD/obtenerCol";
import IngresoImagenCrop from "../../Modulo_formularios/Componentes/IngresoImagenCrop";
import IngresoTexto from "../../Modulo_formularios/Componentes/IngresoTexto";
import {Link, Notes, Tag, Title} from "@mui/icons-material";

const Formulario_Textos_Tours = ({...props}) => {
    const [nomColecciones, setNomColecciones] = useState([])


    useEffect(() => {
        obtenerCol('colecciones').then((dox) => {
            if (dox.res) {
                let data = dox.data
                let noms = data.map((it) => it.nombre)
                setNomColecciones(noms)
            }
        })
    }, []);
    return (
        <form noValidate style={{width: '100%'}}>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
                sx={{p: 1}}
            >


                <Grid item container lg={6} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={1}
                    >

                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                            <Typography sx={{fontWeight: 600}}>Portada Tours</Typography>
                        </Grid>

                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >

                                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                                    <IngresoImagenCrop dato={'imgFondo'} alto={1} ancho={3}
                                                       {...props}
                                    />
                                </Grid>

                                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                                    <Typography>Imagen de fondo</Typography>
                                </Grid>

                            </Grid>
                        </Grid>


                    </Grid>
                </Grid>

                <Grid item container lg={6} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={1}
                    >

                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                            <Typography sx={{fontWeight: 600}}>Toures Mar</Typography>
                        </Grid>


                        <Grid item container lg={4} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                            <IngresoImagenCrop dato={'imgMar'} alto={3} ancho={2}
                                               {...props}
                            />
                        </Grid>


                        <Grid item container lg={8} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                            <IngresoTexto Icono={Notes} nombre={'Descripcion'} dato={'descripcionMar'}
                                          {...props} lineas={5}
                            />
                        </Grid>


                    </Grid>
                </Grid>

                <Grid item container lg={6} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={1}
                    >

                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                            <Typography sx={{fontWeight: 600}}>Toures Aire</Typography>
                        </Grid>


                        <Grid item container lg={4} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                            <IngresoImagenCrop dato={'imgAire'} alto={3} ancho={2}
                                               {...props}
                            />
                        </Grid>


                        <Grid item container lg={8} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                            <IngresoTexto Icono={Notes} nombre={'Descripcion'} dato={'descripcionAire'}
                                          {...props} lineas={5}
                            />
                        </Grid>


                    </Grid>
                </Grid>

                <Grid item container lg={6} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={1}
                    >

                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                            <Typography sx={{fontWeight: 600}}>Toures Tierra</Typography>
                        </Grid>


                        <Grid item container lg={4} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                            <IngresoImagenCrop dato={'imgTierra'} alto={3} ancho={2}
                                               {...props}
                            />
                        </Grid>


                        <Grid item container lg={8} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                            <IngresoTexto Icono={Notes} nombre={'Descripcion'} dato={'descripcionTierra'}
                                          {...props} lineas={5}
                            />
                        </Grid>


                    </Grid>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={1}
                    >

                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                            <Typography sx={{fontWeight: 600}}>Asesorate</Typography>
                        </Grid>


                        <Grid item container lg={2} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                            <IngresoImagenCrop dato={'imgAsesorate'} alto={10} ancho={9}
                                               {...props}
                            />
                        </Grid>


                        <Grid item container lg={4} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                spacing={1}
                            >

                                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                                    <IngresoTexto Icono={Title} nombre={'Titulo'} dato={'tituloAsesorate'}
                                                  {...props}
                                    />
                                </Grid>


                                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                                    <IngresoTexto Icono={Link} nombre={'Link Calendy'} dato={'link'}
                                                  {...props}
                                    />
                                </Grid>

                            </Grid>

                        </Grid>

                        <Grid item container lg={6} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>


                            <IngresoTexto Icono={Notes} nombre={'Descripcion'} dato={'descripcionAsesorate'}
                                          {...props} lineas={5}
                            />


                        </Grid>


                    </Grid>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={3}
                    >

                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                            <Typography sx={{fontWeight: 600}}>Indicadores</Typography>
                        </Grid>

                        <Grid item container lg={4} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                            <IngresoImagenCrop dato={'imgEquipo'} alto={1} ancho={4}
                                               {...props}
                            />
                        </Grid>


                        <Grid item container lg={8} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                spacing={2}
                            >
                                <Grid item container lg={6} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="flex-start"
                                        spacing={1}
                                    >

                                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                                            <IngresoTexto Icono={Tag} nombre={'Numero'} dato={'numeroUno'}
                                                          {...props}
                                            />
                                        </Grid>


                                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                                            <IngresoTexto Icono={Title} nombre={'Indicador'} dato={'indicadorUno'}
                                                          {...props}
                                            />
                                        </Grid>

                                    </Grid>

                                </Grid>

                                <Grid item container lg={6} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="flex-start"
                                        spacing={1}
                                    >

                                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                                            <IngresoTexto Icono={Tag} nombre={'Numero'} dato={'numeroDos'}
                                                          {...props}
                                            />
                                        </Grid>


                                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                                            <IngresoTexto Icono={Title} nombre={'Indicador'} dato={'indicadorDos'}
                                                          {...props}
                                            />
                                        </Grid>

                                    </Grid>

                                </Grid>

                                <Grid item container lg={6} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="flex-start"
                                        spacing={1}
                                    >

                                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                                            <IngresoTexto Icono={Tag} nombre={'Numero'} dato={'numeroTres'}
                                                          {...props}
                                            />
                                        </Grid>


                                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                                            <IngresoTexto Icono={Title} nombre={'Indicador'} dato={'indicadorTres'}
                                                          {...props}
                                            />
                                        </Grid>

                                    </Grid>

                                </Grid>

                                <Grid item container lg={6} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="flex-start"
                                        spacing={1}
                                    >

                                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                                            <IngresoTexto Icono={Tag} nombre={'Numero'} dato={'numeroCuatro'}
                                                          {...props}
                                            />
                                        </Grid>


                                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                                            <IngresoTexto Icono={Title} nombre={'Indicador'} dato={'indicadorCuatro'}
                                                          {...props}
                                            />
                                        </Grid>

                                    </Grid>

                                </Grid>



                            </Grid>
                        </Grid>



                    </Grid>
                </Grid>


            </Grid>
        </form>

    )

}
export default Formulario_Textos_Tours