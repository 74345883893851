/**************************************************
 * Nombre:       Formulario_Productos
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, Typography} from "@mui/material";
import IngresoImagenCrop from "../../Modulo_formularios/Componentes/IngresoImagenCrop";
import IngresoTexto from "../../Modulo_formularios/Componentes/IngresoTexto";
import {Notes} from "@mui/icons-material";
import IngresoImagenCropLista from "../../Modulo_formularios/Componentes/IngresoImagenCropLista";
import IngresoImagenLista from "../../Modulo_formularios/Componentes/IngresoImagenLista";

const Formulario_Textos_Nosotros = ({...props}) => {

    return (
        <form noValidate style={{width: '100%'}}>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
                sx={{p: 1}}
            >

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={1}
                    >

                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                            <Typography sx={{fontWeight: 600}}>Portada Nosotros</Typography>
                        </Grid>

                        <Grid item container lg={4} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>

                            <IngresoImagenCrop dato={'imgFondo'} alto={2} ancho={3}
                                               {...props}
                            />

                        </Grid>

                        <Grid item container lg={8} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                spacing={1}
                            >

                                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                                    <IngresoTexto Icono={Notes} nombre={'Parrafo Uno'} dato={'parrafoUno'}
                                                  {...props} lineas={5}
                                    />
                                </Grid>

                                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                                    <IngresoTexto Icono={Notes} nombre={'Parrafo Dos'} dato={'parrafoDos'}
                                                  {...props} lineas={5}
                                    />
                                </Grid>

                            </Grid>
                        </Grid>


                    </Grid>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={1}
                    >

                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                            <Typography sx={{fontWeight: 600}}>Mision y Vision</Typography>
                        </Grid>


                        <Grid item container lg={4} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                            <IngresoImagenCrop dato={'imgMision'} alto={3} ancho={2}
                                               {...props}
                            />
                        </Grid>

                        <Grid item container lg={8} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                                <IngresoTexto Icono={Notes} nombre={'Mision'} dato={'mision'}
                                              {...props} lineas={5}
                                />
                            </Grid>
                        </Grid>

                        <Grid item container lg={4} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                            <IngresoImagenCrop dato={'imgVision'} alto={3} ancho={2}
                                               {...props}
                            />
                        </Grid>

                        <Grid item container lg={8} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                                <IngresoTexto Icono={Notes} nombre={'Vision'} dato={'vision'}
                                              {...props} lineas={5}
                                />
                            </Grid>
                        </Grid>


                    </Grid>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={1}
                    >

                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                            <Typography sx={{fontWeight: 600}}>Certificados y Registro</Typography>
                        </Grid>


                        <Grid item container lg={4} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                            <IngresoTexto Icono={Notes} nombre={'Parrafo de Certificados'} dato={'textoCertificado'}
                                          {...props} lineas={8}
                            />

                        </Grid>

                        <Grid item container lg={8} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                            <IngresoImagenCropLista dato={'certificados'} alto={3} ancho={4}
                                                    {...props}
                            />
                        </Grid>


                    </Grid>
                </Grid>


            </Grid>
        </form>

    )

}
export default Formulario_Textos_Nosotros