// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import {getAuth} from 'firebase/auth'
import {getFirestore} from 'firebase/firestore'
import {getStorage} from 'firebase/storage'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
//   overreceptour.colombia@gmail.com
const firebaseConfig = {
    apiKey: "AIzaSyB1XjEm0b0Ng-BPNSYcYw6Cck5FNwaGva0",
    authDomain: "over-receptours.firebaseapp.com",
    projectId: "over-receptours",
    storageBucket: "over-receptours.appspot.com",
    messagingSenderId: "663274637500",
    appId: "1:663274637500:web:e0fed5c3ce80b487e028b9",
    measurementId: "G-LWQ8DCH9BW"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);

export const auth = getAuth(app)
export const fire = getFirestore(app)
export const storage = getStorage(app)