/**************************************************
 * Nombre:       Seccion_Categorias
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     12 Hr
 **************************************************/
import {Button, Grid, useMediaQuery} from "@mui/material";
import {useObtenerColleccionTabla} from "../../Servicios/BD/useObtenerColleccionTabla";
import {useEffect, useState} from "react";
import {useDialogo} from "../../Modulo_dialogos/Hooks/useDialogo";
import TituloNotificaciones from "../../Modulo_dashboard/ComponentesSecundarios/TituloNotificaciones";
import TablaBasica from "../../Modulo_tablas/Componentes/TablaBasica";
import {theme} from "../../Tema";
import Dialogo_Servicio from "../Dialogos/Dialogo_Servicio";
import {DashboardOutlined, Edit} from "@mui/icons-material";
import {contarColeccion} from "../../Servicios/BD/Contadores/contarColeccion";

const Seccion_Servicios = () => {
    const sCell = useMediaQuery(theme.breakpoints.only('xs'))
    const [numColecciones, setNumColecciones] = useState(0)
    const [recargar, setRecargar] = useState(false)
    const {props, cargarNuevamente} = useObtenerColleccionTabla({
        coleccion: 'servicios',
        filtroInicial: 'fecha',
        dirInicial: 'desc'
    })
    const {Dialogo, abrir} = useDialogo({
        Componente: Dialogo_Servicio,
        realizado: () => setRecargar(!recargar),
        titulo: 'Detalle de Servicio',
        grande: false,
    })

    const manejardorClicks = (row, id) => {

        switch (id) {
            case 'editar' :
                abrir(row)
                break
        }

    }

    const botones = [
        <Button
            onClick={() => abrir()}
            startIcon={<DashboardOutlined/>}>Nuevo</Button>
    ]


    useEffect(() => {


        contarColeccion({coleccion: 'servicios'}).then((dox) => {
            if (dox.res) {
                setNumColecciones(dox.data)
            }
        })

        cargarNuevamente()

    }, [recargar]);
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Dialogo/>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start'}}>
                <TituloNotificaciones titulo={'Servicios'} textoComplementario={'en lista'}
                                      textoResaltado={`${numColecciones} Servicios`} botones={botones}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: 5}}>
                <TablaBasica
                    filtros={filtros}
                    datosCabecera={sCell ? datosCabeceraCell : datosCabecera}
                    {...props}
                    click={manejardorClicks}
                    botonesTabla={botonesTabla}
                    numeroFilas={numColecciones}
                />
            </Grid>


        </Grid>
    )

}
export default Seccion_Servicios

const filtros = [
    {
        nombre: 'Nombre',
        propiedad: 'nombre',

    },
    {
        nombre: 'Mas Reciente',
        propiedad: 'fecha',
        direccion: 'desc'

    }
]

const datosCabecera = [
    {
        nombre: 'Fecha',
        direccion: 'left',
        propiedad: 'fecha',
        tipo: 'fecha'

    },
    {
        nombre: 'Nombre',
        direccion: 'left',
        propiedad: 'nombre',

    },
    {
        nombre: 'Subtitulo',
        direccion: 'right',
        propiedad: 'subtitulo',

    }


]


const datosCabeceraCell = [
    {
        nombre: 'Fecha',
        direccion: 'left',
        propiedad: 'fecha',
        tipo: 'fecha'

    },
    {
        nombre: 'Nombre',
        direccion: 'right',
        propiedad: 'nombre',

    }
]

const botonesTabla = [
    {
        nombre: 'Editar',
        icono: Edit,
        id: 'editar'
    },

]



